import React, {useEffect, useRef, useState} from 'react';
import './idea-launch.scss';
import ImageTaxi from "../../../../assets/images/game-assets/launch-no-boost.png";
import ImageTakeoff from "../../../../assets/images/game-assets/launch-boost.png";


const IdeaLaunch = ({
    onAnimationEnd,
    speed,
    ...props
}) => {
    const componentName = 'idea-launch';
    const launchRef = useRef(null);
    const [launchState, setLaunchState] = useState('taxi');

    useEffect(() => {
        const handleAnimationEnd = (event) => {
            if (event.animationName === 'taxi') {
                setLaunchState('take-off');
                onAnimationEnd();   //  emits
            }
        };

        const launchElement = launchRef.current;
        if (launchElement) {
            launchElement.addEventListener('animationend', handleAnimationEnd);
        }

        return () => {
            if (launchElement) {
                launchElement.removeEventListener('animationend', handleAnimationEnd);
            }
        };
    }, [onAnimationEnd]);

    return (
        <img
            ref={launchRef}
            className={`${componentName} speed-${speed} ${launchState}`}
            src={launchState === 'taxi' ? ImageTaxi : ImageTakeoff}
            alt="Successful ideas being laucned"
        />
    );
};

export default IdeaLaunch;
