import React from 'react';
import IconHeader from './../../../../assets/images/intro-modal-1.png';
import MotionSlideInFromRight from "../../../../common/components/_animation/MotionSlideInFromRight";
import MotionFadeInUp from "../../../../common/components/_animation/MotionFadeInUp";


const IntroBuildAndUpgrade = (props) => {
    return (
        <>
            <MotionFadeInUp className="icon-container">
                <img
                    className="stage-icon"
                    src={IconHeader}
                    alt="Create ideas"
                />
            </MotionFadeInUp>

            <MotionSlideInFromRight>
                <h1 className="modal-content-header">Build & Upgrade</h1>

                <p>Upgrade your factory, speed up production, and watch your revenue soar. More upgrades and more speed equals more revenue!</p>
            </MotionSlideInFromRight>
        </>
    );
};

export default IntroBuildAndUpgrade;
