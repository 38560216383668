import React from 'react';
import IconHeader from './../../../../assets/images/intro-modal-3.png';
import MotionSlideInFromRight from "../../../../common/components/_animation/MotionSlideInFromRight";
import MotionFadeInUp from "../../../../common/components/_animation/MotionFadeInUp";


const IntroEarnEvenMore = (props) => {
    return (
        <>
            <MotionFadeInUp className="icon-container">
                <img
                    className="stage-icon"
                    src={IconHeader}
                    alt="Boosts button"
                />
            </MotionFadeInUp>

            <MotionSlideInFromRight>
                <h1 className="modal-content-header">Earn Even More</h1>

                <p>Utilize Boosts to increase your earnings! Invite your friends and engage with our social channels to climb the leaderboard even faster!</p>
            </MotionSlideInFromRight>
        </>
    );
};

export default IntroEarnEvenMore;
