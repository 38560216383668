import React from 'react';
import './help.scss';
import Icon from "../../../../common/components/atoms/Icon/Icon";


const GameHelp = () => {
    return (
        <section className="game-help">
            <h1 className="modal-content-header">
                <Icon name="info"/>
                Info
            </h1>

            <article className="info-content">
                <p className="section-heading">
                    <b>Goal</b>
                </p>
                <p className="small">
                    <strong>Generate as much revenue as you can and win your cut of a $1,000,000 $SLING reward!</strong>
                </p>
            </article>

            <article className="info-content">
                <p className="section-heading">
                    <b>Factory</b>
                </p>
                <ul className="small">
                    <li>Welcome to the driver’s seat of your very own production line! It’s your moneymaking machine, so keep it running smoothly to rake in that sweet revenue.</li>
                    <li>Use your earnings to level up your factory - better machines mean faster, bigger profits!</li>
                    <li>Upgraded factories don’t just work harder; they work smarter, earning you more cash in less time.</li>
                    <li>The best part? Your factory keeps churning out money even while you’re away! But here’s the trick: pop back in often to reinvest your profits and supercharge your upgrades for even faster gains.</li>
                </ul>
            </article>

            <article className="info-content">
                <p className="section-heading">
                    <strong>Board</strong>
                </p>
                <p className="small">The leaderboard shows your rank. The higher the rank, the larger your reward at the end of the season.</p>
            </article>

            <article className="info-content">
                <p className="section-heading">
                    <strong>Boosts & Upgrades</strong>
                </p>
                <p className="small">
                    <ul>
                        <li>Upgrade with in-game revenue</li>
                        <li>Earn extra revenue with Boosts</li>
                    </ul>
                </p>
            </article>

            <article className="info-content">
                <strong>Ready to grow your empire? Let’s get to work! </strong>
            </article>
        </section>
    );
};

export default GameHelp;
