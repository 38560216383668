import React from 'react';
import './home.scss';
import { useGetLeaderboard } from "../../../../data/useGetLeaderboard";
import LogoStandaloneSeason from './../../../../assets/images/logo-standalone-season.webp';
import Unicorn from "../../../../assets/images/unicorn.png";
import MoneyBag from "../../../../assets/images/game-assets/money-bag.webp";
import Icon from "../../../../common/components/atoms/Icon/Icon";
import { ordinal_suffix_of } from "../../../../common/utils/maths";

const GameHome = (props) => {
    const {isPending, isError, data} = useGetLeaderboard(props.username);

    const showFactory = () => {
        props.showFactory();
    };

    const showContent = (toShow) => {
        props.toggleContent(toShow);
    };

    return (
        <main className="game-homescreen">
            <img
                className="logo"
                src={LogoStandaloneSeason}
                alt="Game Factory season logo"
            />

            <section className="homescreen-my-revenue">
                <p>Total Generated Revenue</p>
                {/*TODO not sure if toLocalString correct way to do this */}
                <h1>${props?.score.toLocaleString() ?? 0}</h1>
                <p>
                    <Icon name="trophy-outline" />
                    &nbsp; {ordinal_suffix_of(data?.player[2]) ?? ''} &nbsp;
                    <Icon name="trophy-outline" />
                </p>

                <button
                    className="btn small"
                    onMouseDown={showFactory}
                >
                    Go to my Factory
                </button>
            </section>

            <section className="homescreen-split-content">
                <div className="card">
                    <p>
                    <b>Invite Friends</b>
                    </p>
                    <p className="small">Invite friends to your Factory and unlock huge boosts!</p>
                    <button
                        className="btn small full"
                        onMouseDown={async () => {
                            if (navigator?.clipboard?.writeText) {
                                await navigator.clipboard.writeText('https://gf.slingshotdao.com?f=' + (props?.invite[1] ?? ''));
                            }
                        }}
                    >
                        Copy Link
                    </button>
                </div>

                <div className="card">
                    <p>
                        <b>Win $SLING</b>
                    </p>
                    <p className="small">Boost your revenue, help your factory and win big!</p>
                    <button
                        className="btn small full"
                        onMouseDown={() => showContent('leaderboard')}
                    >
                        Leaderboard
                    </button>
                </div>
            </section>

            <section className="card asset-left homescreen-full-content">
                <img
                    className="unicorn"
                    src={Unicorn}
                    alt="Unicorn avatar"
                />
                <p>
                    <b>Boost to win big!</b>
                </p>
                <p className="small">Maximise your revenue with boosts!</p>
                <button
                    className="btn small"
                    onMouseDown={() => showContent('boosts')}
                >
                    Unlock Boosts
                </button>
            </section>

            <section className="card asset-right homescreen-full-content">
                <p>
                    <b>Earn $5 in $SLING</b>
                </p>
                <p className="small">Provide your feedback and earn $SLING!</p>
                <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSexhaJwgHhgiBvrZbV6VpidEvDjqG5B1SiJo5RZoAoaLhKq-A/viewform?usp=sf_link"
                    title="Feedback link"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <button className="btn small">Provide Feedback</button>
                </a>
                <img
                    className="money-bag"
                    src={MoneyBag}
                    alt="Money bag"
                />
            </section>

            <a href="/signout">
                <button className="btn small">
                    Sign Out
                </button>
            </a>
        </main>
    );
};

export default GameHome;
