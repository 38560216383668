import React from 'react';
import IconHeader from './../../../../assets/images/intro-modal-0.png';
import MotionSlideInFromRight from "../../../../common/components/_animation/MotionSlideInFromRight";
import MotionFadeInUp from "../../../../common/components/_animation/MotionFadeInUp";


const IntroWelcome = (props) => {
    return (
        <>
            <MotionFadeInUp className="icon-container">
                <img
                    className="stage-icon"
                    src={IconHeader}
                    alt="Boosts button"
                />
            </MotionFadeInUp>

            <MotionSlideInFromRight>
                <h1 className="modal-content-header">Welcome to Season 3</h1>

                <p>We heard you! Factories are closed, now it's every player for themselves! Enjoy offline earnings and new upgrades. The scores are reset, race to the top for your share of $1M $SLING!</p>
            </MotionSlideInFromRight>
        </>
    );
};

export default IntroWelcome;
