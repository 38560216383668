import React from 'react';
import IconHeader from './../../../../assets/images/intro-modal-2.png';
import MotionSlideInFromRight from "../../../../common/components/_animation/MotionSlideInFromRight";
import MotionFadeInUp from "../../../../common/components/_animation/MotionFadeInUp";


const IntroCompeteAndWinBig = (props) => {
    return (
        <>
            <MotionFadeInUp className="icon-container">
                <img
                    className="stage-icon"
                    src={IconHeader}
                    alt="Scoreboard with leader"
                />
            </MotionFadeInUp>

            <MotionSlideInFromRight>
                <h1 className="modal-content-header">Compete & Win Big</h1>

                <p>Climb the leaderboard! Beat other players and rank high for a chance to win big in the $1,000,000 prize pool, paid in $SLING.</p>
            </MotionSlideInFromRight>
        </>
    );
};

export default IntroCompeteAndWinBig;
