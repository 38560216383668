import React, { useState } from 'react';
import "./intro.css";
import Game from './../../actions/game.js';
import LogoCombinedSeason from './../../assets/images/logo-combined-season.webp';
import Modal from '../../common/components/molecules/Modal/Modal';
import IntroWelcome from "./_sub/introWelcome/introWelcome";
import IntroBuildAndUpgrade from "./_sub/introBuildAndUpgrade/introBuildAndUpgrade";
import IntroCompeteAndWinBig from "./_sub/introCompeteAndWinBig/introCompeteAndWinBig";
import IntroEarnEvenMore from "./_sub/introEarnEvenMore/introEarnEvenMore";
import ProgressIndicator from "../../common/components/molecules/ProgressIndicator/ProgressIndicator";


const Intro = () => {
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [activeStage, setActiveStage] = useState(0);

    const handleStageChange = (newStage) => {
        if (newStage >= 0 && newStage < contentStages.length) {
            setActiveStage(newStage);
        }
    };

    const contentStages = [
        {
            component: <IntroWelcome onClick={() => handleStageChange(activeStage + 1)} />,
            showHeader: true,
            showFooter: true,
        },
        {
            component: <IntroBuildAndUpgrade onClick={() => handleStageChange(activeStage + 1)} />,
            showHeader: true,
            showFooter: true,
        },
        {
            component: <IntroCompeteAndWinBig onClick={() => handleStageChange(activeStage + 1)} />,
            showHeader: true,
            showFooter: true,
        },
        {
            component: <IntroEarnEvenMore onClick={() => handleStageChange(activeStage + 1)} />,
            showHeader: true,
            showFooter: true,
        },
    ];

    const renderModalContent = () => {
        const content = contentStages[activeStage];
        if (!content) return null;

        return (
            <Modal
                type={'has-icon'}
                header={
                    content.showHeader && (
                        <img
                            className="into-header-logo"
                            src={LogoCombinedSeason}
                            alt="Game Factory season logo"
                            onMouseDown={() => {
                                setActiveStage(0);
                            }}
                        />
                    )
                }
                footer={
                    content.showFooter && (
                        <>
                            {activeStage < (contentStages.length - 1) ? (
                                <button
                                    className="btn"
                                    onMouseDown={() => handleStageChange(activeStage + 1)}
                                >
                                    Next
                                </button>
                            ) : (
                                <button
                                    className="btn"
                                    onMouseDown={() => {
                                        Game.joinFactory(0)
                                        // setIsModalOpen(false);
                                    }}
                                >
                                    Get Started
                                </button>
                            )}
                        </>
                    )
                }
            >
                {content.component}

                {activeStage < contentStages.length && (
                    <ProgressIndicator
                        activeStage={activeStage}
                        onStageChange={handleStageChange}
                        stages={contentStages.length}
                    />
                )}
            </Modal>
        );
    };

    return (
        <div className="page-intro">
            {isModalOpen && renderModalContent()}
        </div>
    );
};

export default Intro;
