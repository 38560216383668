import React, { useState } from 'react';
import './leaderboard-member-card.css';
import Icon from "../../atoms/Icon/Icon";
import { ordinal_suffix_of } from "../../../utils/maths";


const truncateAddress = (address) => {
    return !address
        ? ''
        : `${address.substring(0, 6)}...${address.substring(
            address.length - 4,
            address.length
        )}`;
};


const LeaderboardMemberCard = ({
    canEdit = false,
    cardData = [
        "0x123fF43gg953dff2f0", // address
        "123456789000", // revenue
        'Mike' // name
    ],
    onSave, // Function to emit changes to the parent
    ...props
}) => {
    const componentName = 'leaderboard-member-card';
    const [isEditingUser, setEditingUser] = React.useState(false);
    const [username, setUsername] = useState('');  // TODO @Mike if adding a username this index will need to update

    const toggleEditUsername = () => {
        if (!canEdit) return;

        setEditingUser(prevState => !prevState);
    }

    const handleSave = () => {
        if (onSave) {
            onSave(username); // Emit changes to the parent
        }
        setEditingUser(false);
    };


    return (
        <article
            className={`
                ${componentName}
                ${canEdit ? 'can-edit' : ''}
                ${isEditingUser ? 'editing' : ''}
            `}
        >
            <div className="wrapper">
                <div
                    className={`
                        username-container
                        ${cardData[3] ? 'online' : 'offline'}
                    `}
                    onClick={() => toggleEditUsername()}
                >
                    {cardData[2] !== '' && (
                        <span className="name">{cardData[2]}</span>
                    )}

                    <b className="wallet-and-edit">
                        {canEdit && (
                            <Icon name="edit"/>
                        )}
                        {truncateAddress(cardData[0])}
                    </b>
                </div>

                <div className="rank-and-score">
                    <span
                        className={`
                            rank
                            pos-${props.pos}
                        `}
                    >
                        {props?.pos <= 3 && (
                            <Icon name="trophy" />
                        )}
                        {ordinal_suffix_of(props?.pos ?? 1)}
                    </span>

                    <span className="score">
                        ${cardData[1].toLocaleString()}
                    </span>
                </div>
            </div>

            {canEdit && (
                <div className="wrapper">
                    <input
                        type="text"
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Enter player name"
                        value={username}
                    />

                    <small
                        className="cta"
                        onClick={() => toggleEditUsername()}
                    >
                        Cancel
                    </small>

                    <button
                        className="btn small"
                        onClick={handleSave}
                    >
                        Save
                    </button>
                </div>
            )}
        </article>
    );
};

export default LeaderboardMemberCard;
