import { useQuery, useQueryClient } from '@tanstack/react-query';
import { httpGet } from "./httpGet";

export const useGetLeaderboard = (username) => useQuery(
    {
        queryKey: ['leaderboard', username],
        queryFn: () => httpGet(process.env.REACT_APP_WS_URL + (username ? ('/top10/' + username) : 'top10')),
        refetchOnMount: true,
        refetchOnWindowFocus: true
    }
);
